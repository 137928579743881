.App {
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .App { background-color: #282c34; }
  .over-header { 
    color: #332884;
    background-color: white;
  }
  .MuiTypography-root{
    color: white;
  }
  .MuiSvgIcon-root {
    color: white !important;
  }
  .MuiPaper-root {
    color: white !important;
    background-color: #282c34 !important;
  }
  .MuiList-root .MuiLink-root{
      color: white !important;

  }
}

@media (prefers-color-scheme: light) {
  .MuiTypography-root{
    color: #332884;
  }
  .over-header-text{
    color: white;
  }
  .MuiPaper-root {
    color: #332884;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MuiLink-root{
  text-decoration: none !important;
  font-style: italic;
  font-weight: 900;
}
